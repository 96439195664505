<template>
  <v-container class='edit-container px-10' fluid>
    <v-dialog-remove
      :show='isDelete'
      :close='closeDeletePopup'
      :confirm='confirmDelete'
    />
    <snack-bar v-model='isCreateSuccess' :content='snackbarSuccess'></snack-bar>
    <v-popup-loading :isLoading='isLoading' />
    <success-popup :show='isSuccess' :close='closeAlert'></success-popup>
    <alert-error
      :show='isError'
      :msg='msgError'
      :close='closeAlert'
    ></alert-error>
    <v-alert-confirm
      :show='showConfirmDialog'
      :close='closeConfirmUpdate'
      :confirm='confirmUpdate'
      headline-color='warning800Color'
      confirm-btn-color='info600Color'
      icon=''
      title='Update Confirmation'
      subTitle='To update the organization details, all active users in this organization will be forced logout.'
      buttonText='OK'
    ></v-alert-confirm>
    <v-alert-confirm
      t-data='cancel-popup'
      :show='isCancel'
      title='Discard changes'
      subTitle='Are you sure you want to discard all changes?'
      headline-color='black'
      buttonCancel='OK'
      buttonText='Cancel'
      :close='onCancel'
      :confirm='closeCancelPopup'
      icon=''
    ></v-alert-confirm>

    <v-row class='mb-4'>
      <v-col>
        <v-breadcrumbs
          :items='breadcrumbs'
          divider='>'
          class='px-0'
        ></v-breadcrumbs>
        <v-row class='align-center' style='margin-left: 1px; margin-right: 0;'>
          <h3 class='pageHeadline' t-data='page-headline'>
            {{ textMode }} Organization
          </h3>
          <v-spacer></v-spacer>
          <v-btn
            class='ma-2 button-bold'
            color='whiteColor'
            t-data='cancel-btn'
            @click='showCancelPopup'
          >
            Cancel
          </v-btn>
          <v-btn
            v-if='!disabledEdit'
            class='white-text-button ma-2 button-bold'
            color='#1070C4'
            @click='save'
            t-data='save-btn'
          >
            <v-icon left light>
              mdi-content-save-outline
            </v-icon>
            Save
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-form ref='form' lazy-validation>
      <v-card>
        <v-card-title class='mt-0 pt-0 pb-0' t-data='card-title'>
          <h6 class='mb-0 card-title' t-data='info-title'>
            Organization Information
          </h6>
          <v-spacer></v-spacer>
          <p class='edit-field-label sub-body-bold mr-4' t-data='status-title'>
            Status
          </p>
          <v-switch
            :disabled='disabledEdit'
            color='primary'
            v-model='organizationObject.is_active'
            class='mb-0 pb-0'
          ></v-switch>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class='mt-0'>
          <v-row class='mb-4 d-flex'>
            <v-col cols='3' t-data='info-input'>
              <p class='edit-field-label sub-body-bold' t-data='info-label'>
                Header Image <span class='danger500Color--text'>*</span>
              </p>
              <image-dropzone
                title='Header Image'
                subtitle='Recommended size 176x176px.'
                subtitle-font-class='small-bold'
                content-font-class='small'
                class='can-disable-selector'
                :thumbnail='organizationObject.image_url'
                :is-error='isHeaderImageError'
                :error-msg='headerImageErrorMessage'
                :disabled='disabledEdit'
                @update='updateImageHeader'
                @remove='removeImageHeader'
              ></image-dropzone>
            </v-col>
            <v-col cols='6' class='align-self-end'>
              <div t-data='info-input'>
                <p class='edit-field-label sub-body-bold' t-data='info-label'>
                  Initials <span class='danger500Color--text'>*</span>
                </p>
                <v-text-field
                  outlined
                  required
                  dense
                  class='can-disable-selector'
                  :placeholder="isEditMode ? '' : 'Initials (e.g. pssk)'"
                  :rules='initialRules'
                  v-model='organizationObject.initial'
                  :error-messages='errorsOrgCode'
                  :disabled='isEditMode'
                ></v-text-field>
              </div>
              <div t-data='info-input'>
                <p class='edit-field-label sub-body-bold' t-data='info-label'>
                  BU Code <span class='danger500Color--text'>*</span>
                </p>
                <v-text-field
                  outlined
                  required
                  dense
                  class='can-disable-selector'
                  :placeholder="isEditMode ? '' : 'BU Code (JTN03)'"
                  :rules='buCodeRules'
                  v-model='organizationObject.bu_code'
                  :disabled='disabledEdit'
                  onkeypress='if(this.value.length==5) return false;'
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols='3' t-data='info-input'>
              <p class='edit-field-label sub-body-bold' t-data='info-label'>
                Facebook
              </p>
              <v-text-field
                outlined
                required
                dense
                class='can-disable-selector'
                :placeholder="
                  disabledEdit ? '' : 'https://www.facebook.com/princ'
                "
                v-model='organizationObject.facebook'
                :rules='UrlRules'
                :disabled='disabledEdit'
                @input='clearFacebookName'
              ></v-text-field>
            </v-col>
            <v-col cols='3' t-data='info-input'>
              <p class='edit-field-label sub-body-bold' t-data='info-label'>
                Facebook Name
              </p>
              <v-text-field
                outlined
                required
                dense
                class='can-disable-selector'
                :placeholder="
                  disabledEdit || isEmptyFacebook ? '' : 'Princhealth'
                "
                v-model='organizationObject.facebook_name'
                :disabled='disabledEdit || isEmptyFacebook'
              ></v-text-field>
            </v-col>
            <v-col cols='3' t-data='info-input'>
              <p class='edit-field-label sub-body-bold' t-data='info-label'>
                Line Official
              </p>
              <v-text-field
                outlined
                required
                dense
                class='can-disable-selector'
                :placeholder="disabledEdit ? '' : 'https://page.line.me/princ'"
                v-model='organizationObject.line_official'
                :rules='UrlRules'
                :disabled='disabledEdit'
                @input='clearLineName'
              ></v-text-field>
            </v-col>
            <v-col cols='3' t-data='info-input'>
              <p class='edit-field-label sub-body-bold' t-data='info-label'>
                Line Name
              </p>
              <v-text-field
                outlined
                required
                dense
                class='can-disable-selector'
                :placeholder="
                  disabledEdit || isEmptyLine ? '' : '@Princhealth'
                "
                v-model='organizationObject.line_name'
                :disabled='disabledEdit || isEmptyLine'
              ></v-text-field>
            </v-col>
            <v-col cols='3' t-data='info-input'>
              <p class='edit-field-label sub-body-bold' t-data='info-label'>
                Website
              </p>
              <v-text-field
                outlined
                required
                dense
                class='can-disable-selector'
                :placeholder="disabledEdit ? '' : 'https://www.princ.com'"
                v-model='organizationObject.website'
                :rules='UrlRules'
                :disabled='disabledEdit'
              ></v-text-field>
            </v-col>
            <v-col cols='3' t-data='info-input'>
              <p class='edit-field-label sub-body-bold' t-data='info-label'>
                Messenger
              </p>
              <v-text-field
                outlined
                required
                dense
                class='can-disable-selector'
                :placeholder="
                  disabledEdit ? '' : 'https://m.me/princhealth'
                "
                v-model='organizationObject.facebook_messenger_official'
                :rules='UrlRules'
                :disabled='disabledEdit'
              ></v-text-field>
            </v-col>
            <v-col cols='3' t-data='info-input'>
              <p class='edit-field-label sub-body-bold' t-data='info-label'>
                Email
              </p>
              <v-text-field
                outlined
                required
                dense
                class='can-disable-selector'
                :placeholder="disabledEdit ? '' : 'princ@gmail.com'"
                v-model='organizationObject.email'
                :rules='EmailRules'
                :disabled='disabledEdit'
              ></v-text-field>
            </v-col>
            <v-col cols='3' t-data='info-input'>
              <p class='edit-field-label sub-body-bold' t-data='info-label'>
                Phone
              </p>
              <v-text-field
                outlined
                required
                dense
                class='can-disable-selector'
                :placeholder="disabledEdit ? '' : '(+66)2-080-5999'"
                v-model='organizationObject.phone'
                :disabled='disabledEdit'
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols='6' t-data='info-input'>
              <p class='edit-field-label sub-body-bold' t-data='info-label'>
                Fax
              </p>
              <v-text-field
                outlined
                required
                dense
                class='can-disable-selector'
                :placeholder="disabledEdit ? '' : '02-009-9001'"
                v-model='organizationObject.fax'
                :disabled='disabledEdit'
              ></v-text-field>
            </v-col>
            <v-col cols='3' t-data='info-input'>
              <p class='edit-field-label sub-body-bold' t-data='info-label'>
                Latitude (example 13.5671466) <span class='danger500Color--text'>*</span>
              </p>
              <v-text-field
                outlined
                required
                dense
                class='can-disable-selector'
                :placeholder="disabledEdit ? '' : 'Latitude'"
                v-model='organizationObject.latitude'
                :rules='LatRules'
                type='number'
                onkeypress='if(this.value.length==10) return false;'
                :disabled='disabledEdit'
              ></v-text-field>
            </v-col>
            <v-col cols='3' t-data='info-input'>
              <p class='edit-field-label sub-body-bold' t-data='info-label'>
                Longitude (example 100.1234567) <span class='danger500Color--text'>*</span>
              </p>
              <v-text-field
                outlined
                required
                dense
                class='can-disable-selector'
                :placeholder="disabledEdit ? '' : 'Longtitude'"
                v-model='organizationObject.longitude'
                :rules='LngRules'
                type='number'
                onkeypress='if(this.value.length==11) return false;'
                :disabled='disabledEdit'
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols='6' t-data='info-input'>
              <p class='edit-field-label sub-body-bold' t-data='info-label'>
                Binding Card Logo <span class='danger500Color--text'>*</span>
              </p>
              <image-dropzone
                :thumbnail='organizationObject.binding_card_logo_url'
                :is-error='isBindingImageError'
                :error-msg='bindingImageErrorMessage'
                :disabled='disabledEdit'
                @update='updateImageBinding'
                @remove='removeImageBinding'
              ></image-dropzone>
            </v-col>
          </v-row>
          <v-expansion-panels :value='0' class='mt-4 mb-4'>
            <v-expansion-panel outlined>
              <v-expansion-panel-header
                @click='checkThaiContentExpand'
                disable-icon-rotate
                t-data='thai-info-header'
              >
                <h6 class='mb-0 card-title'>
                  Thai
                </h6>
                <template v-slot:actions>
                  <v-icon color='primary'>
                    {{ isThaiContentExpand ? '$minus' : '$plus' }}
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content t-data='thai-info-content'>
                <information
                  :disabledEdit='disabledEdit'
                  :isEditMode='isEditMode'
                  :name='organizationObject.name_th'
                  :address='organizationObject.address_th'
                  lang='th'
                  @changeName='changeNameTh'
                  @changeAddress='changeAddressTh'
                ></information>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-expansion-panels :value='0' class='mb-4'>
            <v-expansion-panel outlined>
              <v-expansion-panel-header
                @click='checkThaiContentExpand'
                disable-icon-rotate
                t-data='eng-info-header'
              >
                <h6 class='mb-0 card-title'>
                  English
                </h6>
                <v-checkbox
                  v-model='duplicateContent'
                  hide-details
                  label='Duplicate Thai content'
                  color='secondary'
                  class='check-box-text body-regular mt-0 pt-0'
                  :disabled='disabledEdit'
                  @click='duplicateThaiContent'
                ></v-checkbox>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <template v-slot:actions>
                  <v-icon color='primary'>
                    {{ isEngContentExpand ? '$minus' : '$plus' }}
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content t-data='eng-info-content'>
                <information
                  :disabledEdit='disabledEdit'
                  :isEditMode='isEditMode'
                  :name='organizationObject.name_en'
                  :address='organizationObject.address_en'
                  lang='en'
                  @changeName='changeNameEn'
                  @changeAddress='changeAddressEn'
                ></information>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <meta-content
           :disabledEdit="disabledEdit"
          ></meta-content>
        </v-card-text>
      </v-card>
    </v-form>
    <v-card v-if='isEditMode' class='mt-4' t-data='user-detail'>
      <v-card-title class='mt-0 pt-0 pb-0' t-data='card-title'>
        <h6 class='mb-0 card-title' t-data='info-title'>
          User in Organization
        </h6>
      </v-card-title>
      <v-data-table
        :headers='userHeader'
        :items='organizationObject.user_org'
        class='elevation-1 mt-2'
      >
        <template v-slot:item.email='{item}'>
          <span>{{ item.email }}</span>
        </template>
        <template v-slot:item.fullname='{item}'>
          <span>{{ item.first_name }} {{ item.last_name }}</span>
        </template>
        <template v-slot:item.action='{item}'>
          <v-icon
            :disabled='disabledEdit'
            t-data='delete-action'
            class='px-2 action-icon'
            @click='deleteUser(item)'
          >mdi-trash-can-outline
          </v-icon
          >
        </template>
      </v-data-table>
      <div class='table-footer-prepend' t-data='total-result-content'>
        <div class='vertical-center pl-4'>
          <b>{{ organizationObject.user_org.length }}</b> Search results
        </div>
      </div>
    </v-card>
    <v-row
      class='d-flex align-start mb-3'
      t-data='footer-row'
      style='margin-left: 1px; margin-right: 0;'
    >
      <created-detail v-if='isEditMode' :data-object='organizationObject' />
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {
  convertDate,
  isUrl,
  snackbarSuccessContent,
  hasPermissionsByOrgs,
  isLowercase,
  isEnglishAlphabet,
  isPng,
  isJpg
} from '@/helper/helper'
import {imageTypeErrorMsg} from '@/constants/constants'
import AlertConfirm from '@/components/AlertConfirm'
import AlertError from '@/components/AlertError'
import CreatedDetail from '@/components/CreatedDetail'
import ImageDropzone from '@/components/ImageDropzone'
import Information from '@/components/organization/Information'
import MetaContent from '@/components/MetaContent'
import PopupLoading from '@/components/PopupLoading'
import RemoveUserPopup from '@/views/organization/RemoveUserPopup'
import SnackBar from '@/components/SnackBar'
import SuccessPopup from './SuccessPopup'

export default {
  components: {
    'v-dialog-remove': RemoveUserPopup,
    'v-popup-loading': PopupLoading,
    'v-alert-confirm': AlertConfirm,
    AlertError,
    CreatedDetail,
    Information,
    SnackBar,
    SuccessPopup,
    ImageDropzone,
    MetaContent
  },
  data() {
    return {
      isEditMode: false,
      isEngContentExpand: true,
      isThaiContentExpand: true,
      duplicateContent: false,
      isSuccess: false,
      isError: false,
      msgError: '',
      isCreateSuccess: false,
      pageName: 'organization',
      userHeader: [
        {text: 'Full Name', value: 'fullname', sortable: false, width: '50%'},
        {text: 'E-mail', value: 'email', sortable: false, width: '40%'},
        {text: 'Action', value: 'action', sortable: false, width: '10%'}
      ],
      isDelete: false,
      isCancel: false,
      userDelete: null,
      showConfirmDialog: false,
      errorsOrgCode: [],
      initialRules: [
        v =>
          !v
            ? 'Initials is required'
            : !isEnglishAlphabet(v)
              ? 'Initials must be English alphabets'
              : isLowercase(v)
                ? true
                : 'Initials must be lowercase only'
      ],
      buCodeRules: [
        v => !!v || 'BU Code is required',
        v => (v && v.length != 5 ? 'BU Code must be 5 digits only' : true)
      ],
      EmailRules: [
        v =>
          !v
            ? true
            : /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v)
              ? true
              : 'E-mail must be valid'
      ],
      UrlRules: [
        v => (v ? (isUrl(v) ? true : 'Support only URL format') : true)
      ],
      LatRules: [
        v => !!v || 'Latitude is required',
        v =>
          v
            ? (isFinite(v) && Math.abs(v) <= 90) || 'Latitude invalid format'
            : true,
        v =>
          v && v.length > 11
            ? 'Ensure that there are no more than 10 digits in total.'
            : true
      ],
      LngRules: [
        v => !!v || 'Longitude is required',
        v =>
          v
            ? (isFinite(v) && Math.abs(v) <= 180) || 'Longtitude invalid format'
            : true,
        v =>
          v && v.length > 12
            ? 'Ensure that there are no more than 11 digits in total.'
            : true
      ]
    }
  },
  async created() {
    this.isEditMode = !!this.$route.params.id
    this.setEditMode(this.isEditMode)
    if (this.isEditMode) {
      await this.setOrgObject(this.$route.params.id)
      this.setMetaObject({ ...this.organizationObject.meta })
    }
  },
  beforeMount() {
    this.resetState()
  },
  watch: {
    async 'organizationObject.initial'() {
      let status = await this.validateName({
        type: 'initial',
        input: this.organizationObject.initial,
        codename: this.isEditMode ? this.organizationObject.codename : ''
      })
      if (status) return (this.errorsOrgCode = [])
      return (this.errorsOrgCode = ['Initials Already Exists'])
    }
  },
  computed: {
    textMode() {
      return this.isEditMode ? 'Edit' : 'Create'
    },
    breadcrumbs() {
      return [
        {
          text: 'App Config',
          disabled: false,
          href: '/'
        },
        {
          text: 'Organization',
          disabled: false,
          href: '/organizations'
        },
        {text: `${this.textMode} Organization`, disabled: true, links: false}
      ]
    },
    snackbarSuccess() {
      return snackbarSuccessContent(this.pageName, 'created')
    },
    createdAt() {
      return convertDate(this.organizationObject.created_at)
    },
    disabledEdit() {
      return (
        this.isEditMode &&
        !hasPermissionsByOrgs('CanEditOrganization', [this.$route.params.id])
      )
    },
    isEmptyFacebook() {
      return this.organizationObject.facebook.length <= 0
    },
    isEmptyLine() {
      return this.organizationObject.line_official.length <= 0
    },
    ...mapState('organization', [
      'bindingImageErrorMessage',
      'delete_users',
      'headerImageErrorMessage',
      'isBindingImageError',
      'isHeaderImageError',
      'isLoading',
      'organizationObject'
    ]),
    ...mapState('metaContent', [
      'metaObject',
    ])
  },
  methods: {
    closeAlert() {
      this.isSuccess = false
      this.isError = false
    },
    closeConfirmUpdate() {
      this.showConfirmDialog = false
    },
    imageBindingRules(val) {
      if (this.isEditMode) {
        this.setBindingImageError({isError: false, message: ''})
        if (isUrl(val)) {
          return
        }
        if (val != '') {
          return
        }
        this.setBindingImageError({
          isError: true,
          message: 'Image Binding is required'
        })
        return
      } else {
        if (!val || val === '') {
          this.setBindingImageError({
            isError: true,
            message: 'Image Binding is required'
          })
          return
        }
        this.setBindingImageError({isError: false, message: ''})
        return
      }
    },
    imageHeaderRules(val) {
      if (this.isEditMode) {
        this.setHeaderImageError({isError: false, message: ''})
        if (isUrl(val)) {
          return
        }
        if (val != '') {
          return
        }
        this.setHeaderImageError({
          isError: true,
          message: 'Image Header is required'
        })
        return
      } else {
        if (!val || val === '') {
          this.setHeaderImageError({
            isError: true,
            message: 'Image Header is required'
          })
          return
        }
        this.setHeaderImageError({isError: false, message: ''})
        return
      }
    },
    validate() {
      this.imageBindingRules(this.organizationObject.binding_card_logo_url)
      this.imageHeaderRules(this.organizationObject.image_url)
      if (
        this.$refs.form.validate() &&
        !this.isHeaderImageError &&
        !this.isBindingImageError &&
        this.organizationObject.binding_card_logo_url !== '' &&
        this.organizationObject.image_url !== ''
      ) {
        return true
      }
      return false
    },
    showCancelPopup() {
      this.isCancel = true
    },
    closeCancelPopup() {
      this.isCancel = false
    },
    onCancel() {
      this.$router.push({name: 'organization-list'})
    },
    clearFacebookName(val) {
      if (val <= 0) {
        this.organizationObject.facebook_name = ''
      }
    },
    clearLineName(val) {
      if (val <= 0) {
        this.organizationObject.line_name = ''
      }
    },
    async confirmUpdate() {
      try {
        this.organizationObject.delete_users = this.delete_users
        let payload = {
          pk: this.$route.params.id,
          object: this.organizationObject
        }
        await this.edit(payload)
        this.$router.push({
          name: 'organization-list',
          query: {isCreateSuccess: true, snackTitle: 'edited'}
        })
      } catch (e) {
        this.isError = true
        this.msgError = 'Incomplete information'
        return
      }
    },
    async save() {
      if (this.validate()) {
        this.organizationObject.meta = { ...this.metaObject }
        this.organizationObject.latitude =
          this.organizationObject.latitude === ''
            ? undefined
            : this.organizationObject.latitude
        this.organizationObject.longitude =
          this.organizationObject.longitude === ''
            ? undefined
            : this.organizationObject.longitude

        if (!this.isEditMode) {
          this.organizationObject.codename = this.organizationObject.initial
          try {
            await this.create(this.organizationObject)
            await this.createAdminRole({
              codename: this.organizationObject.codename,
              is_active: this.organizationObject.is_active
            })
            this.isCreateSuccess = true
            this.isSuccess = true
            this.$refs.form.resetValidation()
            return this.resetState()
          } catch (e) {
            this.isError = true
            this.msgError = e.message
            return
          }
        } else {
          this.showConfirmDialog = true
        }
      }
    },
    checkThaiContentExpand() {
      if (this.isThaiContentExpand) {
        this.isThaiContentExpand = false
      } else {
        this.isThaiContentExpand = true
      }
    },
    checkEngContentExpand() {
      if (this.isEngContentExpand) {
        this.isEngContentExpand = false
      } else {
        this.isEngContentExpand = true
      }
    },
    duplicateThaiContent() {
      if (this.duplicateContent) {
        this.organizationObject.name_en = this.organizationObject.name_th
        this.organizationObject.address_en = this.organizationObject.address_th
      } else {
        this.organizationObject.name_en = ''
        this.organizationObject.address_en = ''
      }
    },
    changeNameTh(name) {
      this.organizationObject.name_th = name
      if (this.duplicateContent) {
        this.organizationObject.name_en = this.organizationObject.name_th
      }
    },
    changeNameEn(name) {
      this.organizationObject.name_en = name
    },
    changeAddressTh(name) {
      this.organizationObject.address_th = name
      if (this.duplicateContent) {
        this.organizationObject.address_en = this.organizationObject.address_th
      }
    },
    changeAddressEn(name) {
      this.organizationObject.address_en = name
    },
    setUserObj(user) {
      this.organizationObject.user_org = this.organizationObject.user_org.filter(
        function(obj) {
          return obj.id !== user.id
        }
      )
      this.setUserDelete(user.id)
    },
    confirmDelete() {
      this.isDelete = false
      this.setUserObj(this.userDelete)
      this.userDelete = null
    },
    closeDeletePopup() {
      this.isDelete = false
      this.userDelete = null
    },
    deleteUser(user) {
      this.isDelete = true
      this.userDelete = user
    },
    updateImageHeader(file) {
      if (file && file.name) {
        if (!isPng(file) && !isJpg(file)) {
          this.setHeaderImageError({
            isError: true,
            message: imageTypeErrorMsg
          })
          return
        } else if (!file || file.size > 1040000) {
          this.setHeaderImageError({
            isError: true,
            message: 'Image size should be less than 1 MB!'
          })
          return
        } else {
          this.setHeaderImage(file)
          this.setHeaderImageError({isError: false, message: ''})
          return
        }
      } else {
        this.organizationObject.image_url = ''
      }
    },
    updateImageBinding(file) {
      if (file && file.name) {
        if (!isPng(file) && !isJpg(file)) {
          this.setBindingImageError({
            isError: true,
            message: imageTypeErrorMsg
          })
          return
        } else if (!file || file.size > 1040000) {
          this.setBindingImageError({
            isError: true,
            message: 'Image size should be less than 1 MB!'
          })
          return
        } else {
          this.setBindingImage(file)
          this.setBindingImageError({isError: false, message: ''})
          return
        }
      } else {
        this.organizationObject.binding_card_logo_url = ''
      }
    },
    removeImageBinding() {
      this.organizationObject.binding_card_logo_url = ''
      this.setBindingImageError({
        isError: true,
        message: 'Image Binding is required'
      })
    },
    removeImageHeader() {
      this.organizationObject.image_url = ''
      this.setHeaderImageError({
        isError: true,
        message: 'Image Header is required'
      })
    },
    ...mapActions('organization', [
      'create',
      'edit',
      'resetState',
      'setBindingImage',
      'setBindingImageError',
      'setEditMode',
      'setHeaderImage',
      'setHeaderImageError',
      'setOrgObject',
      'setUserDelete',
      'validateName'
    ]),
    ...mapActions('rolePermission', ['createAdminRole']),
    ...mapActions('metaContent', ['setMetaObject'])
  }
}
</script>

<style scoped></style>
